import {
  Circle,
  Heart,
  Pause,
  PauseCircle,
  Play,
  PlayCircle,
  Repeat2,
  Shuffle,
  SkipBack,
  SkipForward,
  Volume2,
  VolumeX,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import MediaItem from "../mediaItem/mediaItem";
import Slider from "../slider/slider";
import * as Amplitude from "amplitudejs";
import { useSelector, useDispatch } from "react-redux";
import {
  setPlaybackState,
  setVolume,
  togglePlayPause,
  setRepeat,
  setCurrentSongId,
  setPlayingState,
} from "../../features/player/playerSlice";
import { selectThemeMode } from "../../features/theme/themeSlice";

const formatTime = (timeInSeconds) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};

const Player = ({ selectedSong }) => {
  const dispatch = useDispatch();
  const theme = useSelector(selectThemeMode);

  const player = useSelector((state) => state.player);
  const { isPlaying, volume, repeat, duration, currentTime } = player;

  const VolumeIcon =
    volume === 0 ? (
      <VolumeX className="dark:text-slate-50" size={30} />
    ) : (
      <Volume2 className="dark:text-slate-50" size={30} />
    );

  const handlePlay = () => {
    dispatch(togglePlayPause());
    Amplitude.play();
  };

  const handlePause = () => {
    dispatch(togglePlayPause());
    Amplitude.pause();
  };

  const onPlayNext = () => {
    Amplitude.next();
  };

  const onPlayPrevious = () => {
    Amplitude.prev();
  };

  const handleSliderChange = (value) => {
    const activeIndex = Amplitude.getActiveIndex();
    const songs = Amplitude.getSongs();

    if (
      activeIndex !== null &&
      activeIndex >= 0 &&
      activeIndex < songs.length
    ) {
      const activeSong = songs[activeIndex];
      const newTime = value * duration;

      // Assuming that your songs have a unique identifier (e.g., id)
      const songId = activeSong.id;

      Amplitude.getAudio().currentTime = newTime;
    }
  };

  const handleSliderClick = (percentage) => {
    const newTime = percentage * duration;
    Amplitude.skipTo(newTime);
  };

  const handleVolumeChange = (value) => {
    dispatch(setVolume(value));
    Amplitude.setVolume(value);
  };

  useEffect(() => {
    // Amplitude Initialization
    Amplitude.init({
      bindings: {
        37: "prev",
        39: "next",
        32: "play_pause",
      },
      callbacks: {
        loadedmetadata: () => {
          dispatch(setPlaybackState({ duration: Amplitude.getSongDuration() }));
        },
        timeupdate: () => {
          dispatch(
            setPlaybackState({ currentTime: Amplitude.getSongPlayedSeconds() })
          );
        },
        volumechange: () => {
          dispatch(setVolume(Amplitude.getVolume()));
        },
        song_change: () => {
          // Automatically play the next song when the current one finishes
          Amplitude.play();
        },
        song_end: () => {
          if (repeat) {
            // If repeat is enabled, reset playback position and play the same song
            Amplitude.skipTo(0);
            Amplitude.play();
          } else {
            // If repeat is not enabled, go to the next song in the playlist
            Amplitude.next();
          }
        },
      },
      songs: [
        {
          id: 1,
          name: "First Snow",
          artist: "Emancipator",
          album: "Soon It Will Be Cold Enough",
          url: "https://521dimensions.com/song/FirstSnow-Emancipator.mp3",
          cover_art_url:
            "https://521dimensions.com/img/open-source/amplitudejs/album-art/soon-it-will-be-cold-enough.jpg",
        },
        {
          id: 2,
          name: "Intro / Sweet Glory",
          artist: "Jimkata",
          album: "Die Digital",
          url: "https://521dimensions.com/song/IntroSweetGlory-Jimkata.mp3",
          cover_art_url:
            "https://521dimensions.com/img/open-source/amplitudejs/album-art/die-digital.jpg",
        },
        {
          name: "Offcut #6",
          artist: "Little People",
          album: "We Are But Hunks of Wood Remixes",
          url: "https://521dimensions.com/song/Offcut6-LittlePeople.mp3",
          cover_art_url:
            "https://521dimensions.com/img/open-source/amplitudejs/album-art/we-are-but-hunks-of-wood.jpg",
        },
        {
          id: 3,
          name: "Dusk To Dawn",
          artist: "Emancipator",
          album: "Dusk To Dawn",
          url: "https://521dimensions.com/song/DuskToDawn-Emancipator.mp3",
          cover_art_url:
            "https://521dimensions.com/img/open-source/amplitudejs/album-art/from-dusk-to-dawn.jpg",
        },
        {
          id: 4,
          name: "Anthem",
          artist: "Emancipator",
          album: "Soon It Will Be Cold Enough",
          url: "https://521dimensions.com/song/Anthem-Emancipator.mp3",
          cover_art_url:
            "https://521dimensions.com/img/open-source/amplitudejs/album-art/soon-it-will-be-cold-enough.jpg",
        },
      ],
    });

    // Disable spacebar to prevent page scroll when pressing space to play/pause
    window.onkeydown = function (e) {
      return !(e.keyCode === 32);
    };

    // Cleanup AmplitudeJS when the component unmounts
    return () => {
      Amplitude.stop();
    };
  }, [repeat, dispatch]); // Run this effect only once during the component mount

  useEffect(() => {
    // Handle the selected song change
    if (selectedSong) {
      // Replace the current playlist with the selected song
      Amplitude.addPlaylist("selectedPlaylist", {}, [selectedSong]);

      // Play the selected song
      Amplitude.playNow(selectedSong);

      // Dispatch action to set isPlaying to true
      dispatch(setPlaybackState({ isPlaying: true }));
    }
  }, [selectedSong, dispatch]);

  useEffect(() => {
    // let timeoutId;
    if (isPlaying) {
      Amplitude.play();
    } else {
      Amplitude.pause();
      //    timeoutId = setTimeout(() => {
      //   // After 5 seconds, update the state to trigger the CSS transition
      setPlayingState(false);
      // }, 5000);
    }
    // return () => clearTimeout(timeoutId);
  }, [isPlaying]);

  return (
<div className="flex sm:justify-between justify-center items-center h-full w-full transition-opacity duration-500 dark:bg-slate-900 bg-slate-50 p-2">
  {/* Media and Heart icon - Hidden on small screens */}
  <div className="hidden sm:flex items-center justify-start">
    <MediaItem />
    <Heart className="dark:text-slate-50 ml-2" />
  </div>

  {/* Time, Slider, and Controls - Centered on small screens */}
  <div className="flex flex-col items-center w-full sm:w-[40%] h-full justify-center mb-2">
    <div className="flex items-center w-full gap-2">
      <span className="text-xs font-medium text-sky-500 dark:text-sky-300">
        {formatTime(currentTime)}
      </span>
      <Slider
        value={currentTime / duration || 0}
        onChange={handleSliderChange}
        isVolume={false}
        onSliderClick={handleSliderClick}
      />
      <span className="text-xs font-medium text-sky-500 dark:text-sky-300">
        {formatTime(duration)}
      </span>
    </div>

    {/* Playback controls */}
    <div className="flex justify-center items-center w-full gap-x-6">
      <Shuffle className="dark:text-slate-50" />
      <SkipBack
        onClick={onPlayPrevious}
        size={30}
        className="dark:text-slate-50"
      />
      <div
        onClick={isPlaying ? handlePause : handlePlay}
        className="flex items-center justify-center h-10 w-10 rounded-full bg-white cursor-pointer"
      >
        {isPlaying ? <Pause /> : <Play />}
      </div>
      <SkipForward
        onClick={onPlayNext}
        size={30}
        className="dark:text-slate-50"
      />
      <Repeat2 className="dark:text-slate-50" />
    </div>
  </div>

  {/* Volume controls - Hidden on small screens */}
  <div className="hidden sm:flex items-center justify-center">
    <div className="flex items-center gap-x-2 w-[120px]">
      {VolumeIcon}
      <Slider
        value={volume}
        onChange={handleVolumeChange}
        isVolume={true}
      />
    </div>
  </div>
</div>

  );
};

export default Player;
