import { Heart, MoreVertical, Pause, Play } from "lucide-react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectPlayer } from "../../features/player/playerSlice";
import { musicI } from "../../assets";
import SoundWave from "../soundWave/soundWave";

export function Song({ order, song, name, onSongClick }) {
  const [isHovered, setIsHovered] = useState(false);
  const { currentSongId, isPlaying } = useSelector(selectPlayer);

  return (
    <div
    className={`flex items-center justify-between text-gray-500 w-full py-2 px-2 dark:hover:bg-[#24303F] hover:bg-slate-300 rounded-lg cursor-pointer ${
      isHovered ? "hovered" : ""
    } relative`}
    onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}
    onClick={onSongClick}
  >
    <div className="flex items-center gap-2 flex-grow-0">
      {isHovered ? (
        song.id === currentSongId && isPlaying ? (
          <span onClick={onSongClick} className="hidden sm:inline">
            <Pause />
          </span>
        ) : (
          <span onClick={onSongClick} className="hidden sm:inline">
            <Play />
          </span>
        )
      ) : song.id === currentSongId && isPlaying ? (
        <span className="hidden sm:inline"><SoundWave /></span>
      ) : (
        <p className="hidden sm:inline">{order + 1}</p> 
      )}
  
      <div className="relative h-10 w-10">
        {/* Black overlay on small screens */}
        <img
          className="h-full w-full"
          src={musicI} // Replace with your actual image source
          alt=""
        />
        {song.id === currentSongId && isPlaying && (
          <>
            <div className="absolute inset-0 bg-black opacity-50 sm:hidden"></div> {/* Black overlay */}
            <span className="absolute left-[50%] inset-0 flex justify-center items-center sm:hidden">
              <SoundWave />
            </span>
          </>
        )}
      </div>
      
      <div className="min-w-0">
        <p className="w-36 lg:w-64 text-white truncate">{song.title}</p>
        <p className="w-40 truncate text-xs">{song.artist}</p>
      </div>
    </div>
  
    <div className="flex items-center flex-grow justify-center">
      <p className="w-40 hidden md:inline text-center truncate text-xs">{name}</p>
    </div>
  
    <div className="flex items-center justify-end space-x-4">
      <Heart className="hidden sm:inline" /> {/* Hide heart on small screens */}
      <p className="hidden sm:inline">{song.duration}</p> {/* Hide duration on small screens */}
      <MoreVertical />
    </div>
  </div>
  
  );
}
