
import { ChevronDownIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Songs } from "../songs/songs";
import { playlist } from "../../utils/playlist";
import { album } from "../../utils/album";
import { c3 } from "../../assets";



function Playlist({ title,  backgroundImageUrl }) {
  const backgroundImag = `url(${backgroundImageUrl})`;
	const backgroundImage = `url(${c3})`;
  const { type, id } = useParams();
  // Convert the id parameter to a number
  const playlistId = parseInt(id, 10);
console.log("id check",id)
  let playlistData=[];

  // Determine the playlist data based on the type
  if (type === "playlist") {
    playlistData = playlist.find((playlist) => playlist.id === playlistId);
  } else if (type === "album") {
    playlistData = album.find((album) => album.id === playlistId);
  }

  if (!playlistData) {
    // Handle case when the playlist/album is not found
    return <div>Playlist or Album not found</div>;
  }

  // Assuming your playlistData structure includes a 'songs' field for songs in a playlist
  const playlistSongs = playlistData.songs || [];

  return (
    <div className="flex-grow h-screen w-full">
      <section
        className={`flex items-end  bg-gradient-to-b to-black  h-80 text-white p-8 bg-cover bg-center`}
        style={{ backgroundImage }}
      >
        <div>
          <p>{type === "album" ? "ALBUM" : "PUBLIC PLAYLIST"}</p>
          <h1 className="text-2xl md:text-3xl xl:text-5xl font-bold text-white">{playlistData.title}</h1>
        </div>
      </section>
      <div className="mt-4 w-full">
        <Songs songs={playlistData.songs} name={playlistData.title}/>
      </div>
    </div>
  );
}

export default Playlist;